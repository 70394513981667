import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconFjarrVarme.module.scss';

const IconFjarrVarme = ({ shouldStart = false, id = '' }) => {
    const FjarrVarmeAnimation = () => {
        anime({
            targets: `.${id}heatLine`,
            strokeDashoffset: [anime.setDashoffset, 81.5],
            easing: 'easeInOutSine',
            duration: 1000,
            delay: function (el, i) {
                return i * 250;
            },
            direction: 'forward',
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart ? FjarrVarmeAnimation() : null;
    });

    return (
        <div className={styles['IconFjarrVarme']}>
            <svg
                viewBox="0 0 50 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m24.598 1.88-.593-.805.593.806zM7.613 46.049V16.126h-2v29.922h2zm.408-30.728L25.19 2.686l-1.186-1.61-17.17 12.633L8.02 15.32zm18.362-12.63 16.83 12.623 1.2-1.6-16.83-12.623-1.2 1.6zm17.23 13.423v29.934h2V16.114h-2zm-1 30.934h-34v2h34v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2zm-.4-30.734a1 1 0 0 1 .4.8h2a3 3 0 0 0-1.2-2.4l-1.2 1.6zM25.19 2.686a1 1 0 0 1 1.193.005l1.2-1.6a3 3 0 0 0-3.578-.016l1.185 1.611zM7.613 16.126a1 1 0 0 1 .408-.806l-1.186-1.61a3 3 0 0 0-1.222 2.416h2zm-2 29.922a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1h-2z"
                    fill="currentColor"
                />
                <path
                    className={id + 'heatLine'}
                    d="M25.8 19.095c-.63.724-2.365 3.981.53 9.772 1.99 3.98 1.844 6.876.665 9.771"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <path
                    d="M18.593 19.752a1 1 0 1 0-1.508-1.314l1.508 1.314zm-.224 9.115-.895.447.895-.447zm-.261 9.394a1 1 0 1 0 1.852.754l-1.852-.754zm-1.023-19.823c-.932 1.07-2.662 4.773.39 10.876l1.788-.895c-2.739-5.478-1-8.29-.67-8.667l-1.508-1.314zm.39 10.876c.946 1.894 1.358 3.474 1.416 4.886.058 1.409-.234 2.713-.783 4.061l1.852.754c.63-1.547 1.001-3.138.93-4.896-.073-1.755-.583-3.612-1.627-5.7l-1.789.895zm17.044-9.562a1 1 0 1 0-1.508-1.314l1.508 1.314zm-.224 9.115-.895.447.895-.447zm-.261 9.394a1 1 0 1 0 1.852.754l-1.852-.754zM33.01 18.438c-.932 1.07-2.662 4.773.39 10.876l1.788-.895c-2.74-5.478-1-8.29-.67-8.667l-1.508-1.314zm.39 10.876c.946 1.894 1.358 3.474 1.416 4.886.057 1.409-.234 2.713-.783 4.061l1.852.754c.63-1.547 1.001-3.138.929-4.896-.072-1.755-.582-3.612-1.626-5.7l-1.789.895z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};
IconFjarrVarme.propTypes = {};

export default IconFjarrVarme;
